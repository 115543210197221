import '../css/arcadia/network.css';

import React, { Component } from 'react';
import Stats from './stats';

import logoNetwork from '../img/branding/arcadia_network.webp';

import cardCave from '../img/cards/cave.webp';
import cardChromatis from '../img/cards/chromatis.webp';
import cardFreddy from '../img/cards/freddy.webp';
import cardJan00bl from '../img/cards/jan00bl.webp';
import cardTobi from '../img/cards/tobi.webp';
import cardTom from '../img/cards/tom.webp';

const cards = [cardCave, cardChromatis, cardFreddy, cardJan00bl, cardTobi, cardTom];

class NetworkView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        cards.shuffle();
    }

    render() {
        return (
            <section className="vc_row pt-80 pb-40" id="network-section">
                <svg id="svg8"
                    version="1.1"
                    height="0"
                    width="0">
                    <defs>
                        <clipPath id="network-mask" clipPathUnits="objectBoundingBox">
                            <path
                                style={{ strokeWidth: 1.23844 }}
                                id="path21"
                                transform="scale(3.75)">
                                <animate attributeName="d"
                                    dur="15s"
                                    repeatCount="indefinite"
                                    calcMode="spline"
                                    keySplines=".1,0,.9,1;.1,0,.9,1;.1,0,.9,1;.1,0,.9,1"
                                    values="
                                        M 0,0.24584483 C 1.7015147e-4,0.1866778 8.3533563e-5,0.06516735 8.3533563e-5,0.02304542 0.01451503,0.02304542 0.02142646,0 0.03657542,0 c 0.0165217,0 0.02481272,0.02499877 0.04760152,0.02499877 0.023301,0 0.02871389,-0.02253246 0.0501474,-0.02383114 0.0193276,0 0.0233602,0.0308436 0.0429871,0.0308436 0.0204751,0 0.0240833,-0.03028225 0.0455932,-0.03028225 0.0204177,0 0.019272,0.02098807 0.0416787,0.02098807 0,0.05294839 -10e-9,0.1702189 -10e-9,0.21396408 -0.0164051,0 -0.0158248,0.0211698 -0.0375632,0.0211698 -0.0230918,0 -0.0281783,-0.014051 -0.0508267,-0.014051 -0.0239355,0 -0.0296352,0.0181047 -0.053173,0.0181047 -0.02371652,0 -0.02504711,-0.0172855 -0.04881839,-0.0172855 -0.0206318,0 -0.02403329,0.0199643 -0.04101032,0.0199643 -0.01542067,0 -0.0219599,-0.0189911 -0.03319169,-0.0187386 z;
                                        m 0,0.2356073 c 1.6997591e-4,-0.058559 0,-0.16357083 0,-0.20525991 0.0144166,0 0.0151257,-0.0280586 0.03025903,-0.0280586 0.01650466,0 0.02983734,0.03324187 0.05260262,0.03324187 0.02327697,0 0.03032219,-0.02080092 0.05173358,-0.02208625 0.0193077,0 0.0223807,2.7667e-4 0.0419873,2.7667e-4 C 0.19703651,0.01372108 0.1976382,0 0.21912587,0 c 0.0203966,0 0.0230739,0.03277239 0.0454575,0.03277239 0,0.05240429 -4e-8,0.15821972 -4e-8,0.20151538 -0.0163881,0 -0.0147165,0.0129522 -0.0364325,0.0129522 -0.023068,0 -0.0296507,0.0173434 -0.0522756,0.0173434 -0.0239108,0 -0.0297411,-0.0155813 -0.0532546,-0.0155813 -0.02369206,0 -0.02311037,-0.0178579 -0.04685713,-0.0178579 -0.02061051,0 -0.0255099,0.0330091 -0.04246942,0.0330091 C 0.01788926,0.26415324 0.01122028,0.2353573 0,0.2356073 Z;
                                        m 0,0.26140729 c 1.706205e-4,-0.0590919 0,-0.21786142 0,-0.25992988 0.01447128,0 0.01653939,0.02199071 0.03173011,0.02199071 0.01656724,0 0.02471892,-0.02068474 0.04757054,-0.02068474 0.02336523,0 0.03353736,0.02574673 0.05502995,0.0244497 0.0193809,0 0.0230468,-0.02576506 0.0427278,-0.02576506 0.0205315,0 0.0209418,0.0182634 0.0425109,0.0182634 C 0.2400433,0.01973142 0.24211489,0 0.26458333,0 c 0,0.05288119 0,0.18356319 0,0.22725285 -0.0164503,0 -0.0135753,0.0373305 -0.0353736,0.0373305 -0.0231554,0 -0.0305381,-0.0156785 -0.0532489,-0.0156785 -0.0240014,0 -0.0298539,-0.0160799 -0.0534565,-0.0160799 -0.0237819,0 -0.02397306,0.0258624 -0.04780986,0.0258624 -0.02068868,0 -0.02580041,-0.0130707 -0.04282425,-0.0130707 -0.01546323,0 -0.02060741,0.0155383 -0.03187022,0.0157906 z;
                                        m 0,0.25182561 c 1.7062051e-4,-0.0595956 0,-0.19473285 0,-0.23715992 0.01447128,0 0.01585434,-0.0142046703 0.03104505,-0.0142046703 0.01656725,0 0.025815,0.0165394703 0.04866662,0.0165394703 0.02336523,0 0.03244127,-0.0045648 0.05393386,-0.0058729 0.0193809,0 0.0235949,0.01472336 0.0432759,0.01472336 C 0.19745292,0.02585093 0.19854819,0 0.22011734,0 c 0.020474,0 0.0219975,0.01826419 0.044466,0.01826419 0,0.05333198 5e-8,0.19535267 5e-8,0.23941476 -0.0164503,0 -0.0136722,-0.0138044 -0.0354705,-0.0138044 -0.0231554,0 -0.030635,0.0207088 -0.0533458,0.0207088 -0.0240014,0 -0.0299507,-0.0169366 -0.0535534,-0.0169366 -0.0237819,0 -0.02358553,5.3618e-4 -0.04742233,5.3618e-4 -0.02068867,0 -0.02589729,0.0118249 -0.04292112,0.0118249 -0.0154633,0 -0.02060748,-0.008437 -0.03187023,-0.008182 z;
                                        M 0,0.24584483 C 1.7015147e-4,0.1866778 8.3533563e-5,0.06516735 8.3533563e-5,0.02304542 0.01451503,0.02304542 0.02142646,0 0.03657542,0 c 0.0165217,0 0.02481272,0.02499877 0.04760152,0.02499877 0.023301,0 0.02871389,-0.02253246 0.0501474,-0.02383114 0.0193276,0 0.0233602,0.0308436 0.0429871,0.0308436 0.0204751,0 0.0240833,-0.03028225 0.0455932,-0.03028225 0.0204177,0 0.019272,0.02098807 0.0416787,0.02098807 0,0.05294839 -10e-9,0.1702189 -10e-9,0.21396408 -0.0164051,0 -0.0158248,0.0211698 -0.0375632,0.0211698 -0.0230918,0 -0.0281783,-0.014051 -0.0508267,-0.014051 -0.0239355,0 -0.0296352,0.0181047 -0.053173,0.0181047 -0.02371652,0 -0.02504711,-0.0172855 -0.04881839,-0.0172855 -0.0206318,0 -0.02403329,0.0199643 -0.04101032,0.0199643 -0.01542067,0 -0.0219599,-0.0189911 -0.03319169,-0.0187386 z;
                                        "/>
                            </path>
                        </clipPath>
                    </defs >
                </svg >
                <div className="arc-lqd-particles-bg-wrap lqd-particles-bg-wrap">
                    <div className="ld-particles-container">
                        <div
                            className="ld-particles-inner"
                            id="ld-1539870338666-b91d3ebc-7c61"
                            data-particles="true"
                            data-particles-options='{"particles":{"number":{"value":180},"color":{"value":"#ffffff"},"shape":{"type":["circle"]},"opacity":{"value":0.75,"random":true,"anim":{"enable":true,"opacity_min":0.2,"speed":0,"sync":true}},"size":{"value":3,"random":true,"anim":{"enable":true,"size_min":40,"speed":0}},"move":{"enable":true,"direction":"right","speed":1,"random":true,"out_mode":"out"}},"interactivity":[]}'>
                        </div>{/*.lqd-particles-inner*/}
                    </div>{/*.ld-particles-container*/}
                </div>{/*.lqd-particles-bg-wrap*/}

                <div id="network"></div>

                <div className="container-fluid">
                    <div className="row">

                        <div className="lqd-column col-md-6 col-md-offset-1 ld-fancy-heading mask-text">
                            <h1 data-split-text="true" data-custom-animations="true"
                                data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":1200,"delay":100,"easing":"easeOutQuint","direction":"forward","initValues":{"scale":1},"animations":{"scale":1}}'
                                data-split-options='{"type":"lines"}'>
                                <img style={{ padding: '10px 20%', filter: 'invert()' }} src={logoNetwork} alt="Arcadia Network" />
                                {/*<span className="ld-fh-txt"> Adventures in creative age</span>*/}
                            </h1>

                            <p data-split-text="true" data-custom-animations="true"
                                data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":1200,"delay":100,"easing":"easeOutQuint","direction":"forward","initValues":{"scale":1},"animations":{"scale":1}}'
                                data-split-options='{"type":"lines"}' style={{ left: '10%', width: '80%', position: 'relative' }}>
                                <span className="ld-fh-txt">With Arcadia Network, you can play your favourite Minecraft minigames with the community and your friends – for free! Just join our Discord, select a minigame from a constantly growing library, start a Minecraft server – and let the fun begin!</span>
                            </p>
                        </div>{/* /.ld-fancy-heading */}

                        {/*<div className="lqd-column col-md-6 col-md-offset-1">

                            <header className="fancy-title">
                                <h2>Arcadia Network</h2>
                            </header>

                        </div>{/* /.lqd-column col-md-6 col-md-offset-3 */}

                        <div className="col-md-5 full-center">
                            <div className="vid-container">
                                {/*<iframe title="How2Arcadia" className="vid-frame" src="https://www.youtube-nocookie.com/embed/ur560pZKRfg?controls=0&amp;start=42" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
                            </div>
                        </div>

                    </div>{/* /.row */}
                    <div className="row pt-20">

                        <div className="lqd-column col-md-4 col-sm-offset-1 arc-col-md arc-net-cards">

                            <img className="arc-net-card" id="arc-net-card-back" src={cards[2]} alt="XP Card" />
                            <img className="arc-net-card" id="arc-net-card-mid" src={cards[1]} alt="XP Card" />
                            <img className="arc-net-card" id="arc-net-card-front" src={cards[0]} alt="XP Card" />

                        </div>{/* /.lqd-column col-md-6 col-md-offset-3 */}

                        <div className="lqd-column col-md-6 ld-fancy-heading mask-text mt-40">

                            <header className="fancy-title text-right">
                                <h2 className="text-right">XP, Stats, Leaderboards and more</h2>
                            </header>

                            <p data-split-text="true" data-custom-animations="true"
                                data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":1200,"delay":100,"easing":"easeOutQuint","direction":"forward","initValues":{"scale":1},"animations":{"scale":1}}'
                                data-split-options='{"type":"lines"}' style={{ left: '20%', width: '80%', position: 'relative' }}>
                                <span className="ld-fh-txt">While playing, winning and surviving in minigames on Arcadia Network, you'll earn stats – level up, get higher ranks and climb the leaderboard!</span>
                            </p>

                        </div>{/* /.lqd-column col-md-6 col-md-offset-3 */}

                    </div>{/* /.row */}
                    <div className="row pt-20">
                        {/*<Stats stats={this.props.stats}/>*/}
                    </div>
                </div>{/* /.container */}
            </section >
        );
    }
}

export default NetworkView;