import React, { Component } from 'react';
import placeholder from '../img/screenshots/combatcube/main.webp';
import Slider from '../components/Slider';
import Icon from '../components/Icon';

class Map extends Component {
    render() {
        var map = this.props.map;

        // Author Information
        let creators = [];

        map.creators.forEach((c, i) => {
            if (i > 0) creators.push(", ");
            if (c.link !== null) creators.push(<a key={i} href={c.link}>{c.name}</a>);
            else creators.push(c.name);
        });
        let creator = creators.length > 0 ? <span className="cc-author">by {creators}</span> : null;

        var tlink = null;
        if (map.tlink) {
            tlink = <div className="cc-video">
                <iframe loading="lazy" title={map.name} width="560" height="315" src={"https://www.youtube-nocookie.com/embed/" + map.tlink.split('/').pop() + "?rel=0"} allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderStyle: "hidden" }}/>
            </div>;
        }

        var image = placeholder;
        if (map.link_images_selected) image = map.link_images_selected;

        var dlmap = map.dllink ? <a className="carousel-dl-map" href={map.dllink}><Icon icon="download" /><span>Download</span></a> : null;
        return (
            <div className="col-lg-7 carousel-item">
                <span className="carousel-caption">{map.name}</span>
                <div style={{ backgroundImage: `url(${image})` }} className="carousel-background" />
                <div className="carousel-content">
                    <div className="cc-text">
                        <h4>{map.name}</h4>
                        <p>{map.desc}</p>
                        {creator}
                    </div>
                    {tlink}
                </div>
                <figure>
                    <img className="carousel-front" src={image} onLoad={() => {
                        let evt = new UIEvent('resize', { view: window, bubbles: true, cancelable: false, detail: 0 });
                        window.dispatchEvent(evt);
                    }} alt="Carousel Item" />
                </figure>
                {dlmap}
            </div>
        )
    }
}

class MapView extends Component {
    list() {
        var maps = [];
        for (var i = 0; i < this.props.maps.length * 2; i++) {
            maps.push(<Map map={this.props.maps[i % this.props.maps.length]} key={i} />)
        }
        return maps;
    }

    title() {
        if (this.props.maps[0].id === 0) return <h2>Loading maps...</h2>
        if (this.props.maps[0].id === -1) return <h2>Loading maps failed!</h2>
        return <h2>Play these <span style={{ color: 'rgb(26, 188, 156)', fontSize: '1.5em', position: 'relative', top: '6px' }}>{this.props.maps.length}</span> maps on Arcadia Network!</h2>
    }

    render() {
        return (
            <section className="vc_row pt-80 pb-40" id="maps-section">
                <div id="maps"></div>
                <div className="container">
                    <div className="row">
                        <div className="lqd-column col-md-6 col-md-offset-3">

                            <header className="fancy-title">
                                {this.title()}
                            </header>

                        </div>{/* /.lqd-column col-md-6 col-md-offset-3 */}

                    </div>{/* /.row */}
                </div>{/* /.container */}

                {this.props.maps[0].id !== 0 && this.props.maps[0].id !== -1 ?
                    <div className="container-fluid">
                        <div className="row">

                            <div className="lqd-column col-md-12">

                                <div className="carousel-items row">
                                    <Slider
                                        options={{
                                            cellAlign: "center",
                                            prevNextButtons: false,
                                            pageDots: false,
                                            groupCells: false,
                                            wrapAround: true,
                                            pauseAutoPlayOnHover: false,
                                            adaptiveHeight: false
                                        }}
                                        children={this.list()}
                                    />
                                </div>
                            </div>
                        </div>{/* /.row */}
                    </div> : null}{/* /.container-fluid */}
            </section>
        );
    }
}

export default MapView;