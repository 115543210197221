import React from 'react';
import arcadia from '../img/branding/arcadia.svg';
import arcadiaIcon from '../img/branding/arcadia_icon.svg';

function header() {
    return (
        <header className="main-header main-header-overlay" data-sticky-header="true"
            data-sticky-options='{ "stickyTrigger": "first-section" }'>

            <div className="mainbar-wrap">
                <div className="megamenu-hover-bg"></div>{/* /.megamenu-hover-bg */}
                <div className="container-fluid mainbar-container">
                    <div className="mainbar">
                        <div className="row mainbar-row align-items-lg-stretch px-4">

                            <div className="col-auto">
                                <div className="navbar-header">
                                    <a className="navbar-brand" href="index.html" rel="home">
                                        <span className="navbar-brand-inner">
                                            <img className="logo-dark" src={arcadiaIcon}
                                                alt="Logo"/>
                                            <img className="logo-sticky" src={arcadiaIcon}
                                                alt="Logo" />
                                            <img className="mobile-logo-default" src={arcadia}
                                                alt="Logo" />
                                            <img className="logo-default" src={arcadiaIcon}
                                                alt="Logo"/>
                                        </span>
                                    </a>
                                    <button type="button" className="navbar-toggle collapsed nav-trigger style-mobile"
                                        data-toggle="collapse" data-target="#main-header-collapse"
                                        aria-expanded="false"
                                        data-changeclassnames='{ "html": "mobile-nav-activated overflow-hidden" }'>
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="bars">
                                            <span className="bar"></span>
                                            <span className="bar"></span>
                                            <span className="bar"></span>
                                        </span>
                                    </button>
                                </div>{/* /.navbar-header */}
                            </div>{/* /.col */}

                            <div className="col">

                                <div className="collapse navbar-collapse" id="main-header-collapse">

                                    <ul id="primary-nav"
                                        className="main-nav nav align-items-lg-stretch justify-content-lg-start"
                                        data-submenu-options='{ "toggleType":"fade", "handler":"mouse-in-out" }'
                                        data-localscroll="true">

                                        <li>
                                            <a href="#home">
                                                <span className="link-icon"></span>
                                                <span className="link-txt">
                                                    <span className="link-ext"></span>
                                                    <span className="txt">Home</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#maps">
                                                <span className="link-icon"></span>
                                                <span className="link-txt">
                                                    <span className="link-ext"></span>
                                                    <span className="txt">Maps</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#network">
                                                <span className="link-icon"></span>
                                                <span className="link-txt">
                                                    <span className="link-ext"></span>
                                                    <span className="txt">Arcadia Network</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#team">
                                                <span className="link-icon"></span>
                                                <span className="link-txt">
                                                    <span className="link-ext"></span>
                                                    <span className="txt">Team</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a title="Toolbox for Mapmakers" href="https://dev.arcadialabs.net">
                                                <span className="link-icon"></span>
                                                <span className="link-txt">
                                                    <span className="link-ext"></span>
                                                    <span className="txt">Toolbox</span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>{/* /#primary-nav  */}

                                </div>{/* /#main-header-collapse */}

                            </div>{/* /.col */}

                            <div className="col text-right">

                                <div className="header-module">

                                    <a href="https://discord.arcadialabs.net" target="_blank" rel="noopener noreferrer"
                                        className="btn btn-default text-uppercase circle btn-bordered border-thick font-size-12 font-weight-semibold btn-white">
                                        <span>
                                            <span className="btn-txt">Join us on Discord</span>
                                        </span>
                                    </a>

                                </div>{/* /.header-module */}

                            </div>{/* /.col */}

                        </div>{/* /.mainbar-row */}
                    </div>{/* /.mainbar */}
                </div>{/* /.mainbar-container */}
            </div>{/* /.mainbar-wrap */}

        </header>);
}

export default header;