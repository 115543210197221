import React, { Component } from 'react';

import header from './modules/header';
import TitleView from './modules/title';
import footer from './modules/footer';
import MapView from './modules/maps';
import TeamView from './modules/team';
import NetworkView from './modules/network';

Array.prototype.shuffle = function () {
  for (let i = this.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [this[i], this[j]] = [this[j], this[i]];
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maps: [{ id: 0 }],
      images: []
    };
  }

  componentDidMount() {
    /**/fetch("https://api.arcla.bz/web")/*/fetch("http://localhost:7777/web")/**/
      .then(res => res.json())
      .then(web => {
        let images = [];
        let maps = web.maps;
        maps.forEach((map, index) => {
          if (map.link_images && map.link_images.length > 0) {
            maps[index].link_images_selected = map.link_images[Math.round(Math.random() * (map.link_images.length - 1))]
            images.push(maps[index].link_images_selected);
          }
        });
        console.log(web.stats);
        this.setState({ maps, images, stats: web.stats });
      })
      .catch(ex => {
        this.setState({ maps: [{ id: -1 }] });
      })
  }

  render() {
    return (
      <>
        {header()}
        <main id="content" className="content">
          <TitleView images={this.state.images} />
          <MapView maps={this.state.maps} />
          <NetworkView stats={this.state.stats} />
          <TeamView />
        </main>
        {footer()}
      </>
    );
  }
}

export default App;