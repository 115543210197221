import React from 'react';

function CounterDigit(props) {
    return <span className="liquid-counter-animator">
        <span className="liquid-animator-value">{props.children}</span>
        <div className="liquid-animator-numbers" data-value={props.children}>
            <ul style={{ transform: `translateY(-${props.children}0%)` }}>
                <li>0</li><li>1</li><li>2</li><li>3</li><li>4</li><li>5</li><li>6</li><li>7</li><li>8</li><li>9</li>
            </ul>
        </div>
    </span>
}

function Counter(props) {
    return <div className="lqd-column col-md-3 col-xs-6 text-center">
            <div className="liquid-counter liquid-counter-default liquid-counter-md liquid-counter-has-hover-gradient">
                <div className="liquid-counter-element " data-enable-counter="true" data-counter-options='{"targetNumber":"785","blurEffect":true}'>
                    <div className="liquid-counter-element liquid-counter-element-hover">
                        <span>785</span>
                    </div>
                    <span className="text-dark">785</span>
                </div>
                <span className="liquid-counter-text liquid-text-bottom text-dark font-size-18">GLOBAL BRANDS</span>
            </div>
        </div>;
    let counterDigits = [];
    //console.log(props);
    `${props.count}`.split("").forEach((digit, i) => { counterDigits.push(<CounterDigit key={i}>{digit}</CounterDigit>) });
    return <div className="lqd-column col-md-3 col-xs-6 text-center">
        <div className="liquid-counter liquid-counter-default liquid-counter-md liquid-counter-has-hover-gradient">
            <div className="liquid-counter-element  counter-animated" data-enable-counter="true" data-counter-options={`{"targetNumber":"${props.count}","blurEffect":true}`}>
                <div className="liquid-counter-element liquid-counter-element-hover">
                    <span>{props.count}</span>
                </div>
                <span className="text-dark">
                    {counterDigits}
                </span>
            </div>
            <span className="liquid-counter-text liquid-text-bottom text-dark font-size-18">{props.text}</span>
        </div>
    </div>;
}

function Progress(props) {
    return <div className="lqd-column col-md-3 col-sm-6 col-xs-10 col-xs-offset-1 col-sm-offset-0 px-4">
            <div className="liquid-progressbar ld-prgbr-circle" data-progressbar="true" data-progressbar-options='{ "value": 70, "orientation": "circle" }'>
                <div className="liquid-progressbar-inner">
                    <span className="liquid-progressbar-bar">
                        <span className="liquid-progressbar-percentage"></span>
                    </span>
                    <div className="ld-prgbr-circle-container" data-thickness="10" data-empty-fill="#E6E6E6" data-fill='{ "gradient": ["#221eed", "#c10bea"] }'>
                    </div>
                </div>
                <div className="liquid-progressbar-details">
                    <h3 className="liquid-progressbar-title">Circle Bar</h3>
                </div>
            </div>
        </div>;
}

function Stats(props) {
    if (!props.stats) return null;
    return <section>
        <Progress text="Registered Users" progress={props.stats.registered / props.stats.total * 100} />
        <Counter text="Total Users" count={props.stats.total} />
        <Counter text="XP Earned" count={props.stats.xp} />
        <Counter text="Stats Collected" count={props.stats.stats} />
    </section>
}

export default Stats;