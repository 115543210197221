import React, { Component } from 'react';
import Slider from '../components/Slider';
import Icon from '../components/Icon';
import data from '../data/team.json';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import '../css/arcadia/team.css';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

var _count = 0;

class Member extends Component {
    count = _count++;

    componentDidMount() {
        document.querySelector("#arc-member-" + this.count).style.setProperty('--team-color', `hsl(${Math.floor(Math.random() * 36) * 10}, 12%, 34%)`);
    }

    taskIconFusion() {
        var member = this.props.member;
        var tasks = [];
        if (member.tasks !== undefined)
            for (var i = 0; i < member.tasks.length; i++) {
                var task = member.tasks[i];
                var icon = member.icons !== undefined ? member.icons[i] : undefined;
                tasks.push(
                    <div className="arc-team-task" key={i}>
                        <div className="arc-team-task-icon"><Icon icon={icon} /></div>
                        <div className="arc-team-task-name">{task}</div>
                    </div>
                );
            }
        return <div className="arc-team-tasks">
            {tasks}
        </div>;
    }

    render() {
        var member = this.props.member;
        return (
            <div className="arc-team-card" id={"arc-member-" + this.count}>
                <div className="arc-team-image-frame">
                    <div className="arc-team-image" style={{ backgroundImage: `url(${member.picture_url})` }}></div>
                </div>
                <div className="arc-team-card-frame">
                    <div className="arc-team-socials">
                        {
                            member.socials.twitter !== undefined &&
                                member.socials.twitter !== "" ?
                                <div className="arc-team-twitter">
                                    <Tippy content={`@${member.socials.twitter}`}>
                                        <a href={`https://twitter.com/${member.socials.twitter}`} target="_blank" rel="noopener noreferrer"><Icon icon="twitter" height="32" width="32" /></a>
                                    </Tippy>
                                </div>
                                : null
                        }
                        <div className="arc-team-discord">
                            <Tippy content={member.socials.discord}>
                                <a href="https://discord.arcadialabs.net" target="_blank" rel="noopener noreferrer"><Icon icon="discord" height="32" width="32" /></a>
                            </Tippy>
                        </div>
                    </div>
                    <div className="arc-team-name">{member.name}</div>
                    {this.taskIconFusion()}
                    <div className="arc-team-quote-icon"/>
                    <div className="arc-team-desc">
                        <div style={{whiteSpace: "pre-wrap"}} className="arc-team-quote">
                            {member.description}
                        </div>
                    </div>
                    <div className="arc-team-footer">{member.alias}</div>
                </div>
                <script>

                </script>
            </div>
        )
    }
}

shuffleArray(data);

class TeamView extends Component {
    list() {
        var team = [];
        for (var i = 0; i < data.length * 2; i++) {
            team.push(<Member member={data[i % data.length]} key={i} />);
        }
        return team;
    }

    render() {
        return (
            <section className="vc_row pt-90 pb-90" id="team-section">
                <div id="team"></div>
                <div className="container">
                    <div className="row">

                        <div className="lqd-column col-md-6 col-md-offset-3">

                            <header className="fancy-title text-center">
                                <h2>Meet the Team!</h2>
                            </header>

                        </div>{/* /.lqd-column col-md-6 col-md-offset-3 */}

                    </div>{/* /.row */}
                </div>{/* /.container */}

                <div className="container-fluid">
                    <div className="row">

                        <div className="lqd-column col-md-12">

                            <div className="carousel-items row">
                                <Slider
                                    options={{
                                        cellAlign: "center",
                                        prevNextButtons: false,
                                        pageDots: false,
                                        groupCells: false,
                                        wrapAround: true,
                                        pauseAutoPlayOnHover: false,
                                        adaptiveHeight: true
                                    }}
                                    children={this.list()}
                                />
                            </div>
                        </div>
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}

            </section>
        );
    }
}

export default TeamView;