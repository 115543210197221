import React from 'react';
import arcadia from '../img/branding/arcadia.svg';
import partner_sp from '../img/branding/partner/stickypiston/logo2_tiny.webp';

function footer() {
    return (
        <footer className="main-footer pt-30">

            <section className="bt-fade-white-015 arc-footer">
                <div className="container">
                    <div className="row">

                        <div className="lqd-column col-xs-6 arc-footer-col arc-footer-nav">

                            <h3 className="widget-title">Navigation</h3>
                            <ul className="lqd-custom-menu reset-ul">
                                <li><a href="#home">Home</a></li>
                                <li><a href="#maps-section">Maps</a></li>
                                <li><a href="#network-section">Network</a></li>
                                <li><a href="#team-section">Team</a></li>
                                <li><a title="Toolbox for Mapmakers" href="https://dev.arcadialabs.net/">Toolbox</a></li>
                                <li><a href="/imprint.html">Imprint & Privacy</a></li>
                            </ul>

                        </div>{/* /.col-md-3 col-sm-6 */}

                        <div className="lqd-column col-xs-6 arc-footer-col">

                            <h3 className="widget-title">Social Media</h3>
                            <ul className="lqd-custom-menu reset-ul">
                                <li><a id="arc-dhighlight" href="https://discord.arcadialabs.net">Discord</a></li>
                                <li><a href="https://twitter.arcadialabs.net">Twitter</a></li>
                                <li><a href="https://youtube.arcadialabs.net">YouTube</a></li>
                                {/*<li><a href="#">Blog</a></li>*/}
                            </ul>

                        </div>{/* /.col-md-3 col-sm-6 */}

                        {/*<div className="lqd-column col-md-4 col-sm-6">

                            <h3 className="widget-title">Maps</h3>
                            <ul className="lqd-custom-menu reset-ul">
                                <li><a href="https://www.curseforge.com/minecraft/worlds/combat-cube-2">Combat Cube 2</a></li>
                                <li><a href="https://www.curseforge.com/minecraft/worlds/minute-rush">Minuterush</a></li>
                                <li><a href="https://www.planetminecraft.com/project/spectral-zone/">Spectralzone</a></li>
                                <li>More maps coming soon!</li>
                            </ul>

                        </div>{/* /.col-md-3 col-sm-6 */}

                        {/*<div className="lqd-column col-md-3 col-sm-6">

                            <h3 className="widget-title">More</h3>
                            <ul className="lqd-custom-menu reset-ul">
                                <li><a href="#404">Privacy Policy</a></li>
                            </ul>

                        </div>{/* /.col-md-3 col-sm-6 */}

                    </div>{/* /.row */}
                    <div className="row">

                        <div className="lqd-column col-md-6 col-md-offset-3 text-center">

                        <div className="m-4 arc-footer-branding">
                            {/*<img src={partner_mc} className="arc-footer-branding-icon" alt="Official Minecraft Partner"/>*/}
                            <img src={arcadia} className="arc-footer-branding-icon p-2" alt="Logo" />
                            <a href="https://trial.stickypiston.co/"><img className="arc-footer-branding-icon" src={partner_sp} alt="Sticky Piston Logo" /></a>
                        </div>

                            <p>
                                © 2024 ArcadiaLabs. All Rights Reserved.<br/>
                                All contents are under the copyright of their creators.<br/>
                                <a href="https://www.minecraft.net/">Minecraft</a> is copyright <a href="https://www.mojang.com/">Mojang Studios</a>.
                            </p>

                        </div>{/* /.col-md-6 */}

                    </div>{/* /.row */}
            {/* /.main-footer */}
                </div>{/* /.container */}
            </section>
        </footer>
    );
}

export default footer;