import React, { Component } from 'react';
import icons from '../data/icons.json';

class Icon extends Component {
    render() {
        var _icon = this.props.icon;
        if (_icon === undefined || icons[_icon] === undefined) return null;

        var icon = icons[_icon];
        var width = this.props.width || "24";
        var height = this.props.height || "24"

        return <svg xmlns="http://www.w3.org/2000/svg" className="arc-icon" width={width} height={height} viewBox="0 0 24 24"
            fillRule={icon.fill || ""} clipRule={icon.clip || ""}><path d={icon.path} />
        </svg>;
    }
}

export default Icon;