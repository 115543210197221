import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './css/vendors/liquid-icon/liquid-icon.min.css';
import './css/vendors/font-awesome/css/font-awesome.min.css';
import './css/theme-vendors.min.css';
import './css/theme.min.css';
import './css/themes/original.css';
import './css/themes/creative.css';
import './css/arcadia/arcadia.css';


const root = createRoot(document.getElementById('wrap'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
